<script setup>
    import { computed } from 'vue';
    import { app } from 'o365-modules';

    import AppButton from 'o365.pwa.vue.components.layout.AppButton.vue';

    const isOnHome = computed(() => {
        return app.id === 'pwa-home';
    });

    const showSecondAppButton = computed(() => {
        return !isOnHome.value && (app.config?.pwaSettings?.onlineAppID ?? false);
    });

    const onlineAppIcon = computed(() => {
        return app.config?.pwaSettings?.onlineAppIcon ?? null;
    });

    const onlineAppTitle = computed(() => {
        return app.config?.pwaSettings?.onlineAppTitle ?? null;
    });

    const onlineAppID = computed(() => {
        return app.config?.pwaSettings?.onlineAppID ?? null;
    });
</script>

<template>
    <div class="d-flex flex-column gap-2 p-2">
        <h4 class="m-0">{{ $t('Online') }}</h4>
    
        <hr class="m-0"/>

        <AppButton :buttonText="$t('Online Home')" iconClass="bi-house-fill" openLink="/nt/myhome"></AppButton>
        <AppButton v-if="showSecondAppButton" :buttonText="$t(onlineAppTitle)" :iconClass="onlineAppIcon" :openLink="`/nt/${onlineAppID}`"></AppButton>
    </div>
</template>